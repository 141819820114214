<template>
  <div>
    <base-section
      id="theme-features"
      class="pt-8 pb-6 mb-0"
    >
      <base-section-heading :title="$t('title')">
        {{ $t('subtitle') }}
      </base-section-heading>

      <v-container>
        <v-row no-gutters>
          <v-col
            v-for="(feature, i) in fullFeatures"
            :key="i"
            cols="12"
            md="4"
          >
            <base-avatar-card
              v-bind="feature"
              align="left"
              horizontal
            >
              {{ $t(`t${i}.title`) | uppercase }}
            </base-avatar-card>
          </v-col>
        </v-row>
      </v-container>
  </base-section>
  <div style="background-color: #00bcd4;">
    <base-section-heading
      icon="fas fa-check-circle"
      :icon-size="80"
      :space="0"
      :margin="3"
      :title="$t('s0.title')"
      :text="$t('s0.text')"
      dark
      color="white"
      class="pb-8 pt-16"
    />
  </div>
  </div>
</template>

<script>
  export default {
    name: 'SectionThemeFeatures',

    data: () => ({
      features: [
        {
          color: 'primary',
          dark: true,
          icon: 'fas fa-spray-can',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'fas fa-fish',
        },
        {
          color: 'primary',
          dark: true,
          icon: 'fas fa-leaf',
        },
      ],
    }),
    computed: {
      fullFeatures () {
        return this.features.map((feature, i) => {
          feature.text = this.$i18n.t(`t${i}.text`)
          feature.title = this.$i18n.t(`t${i}.title`)
          return feature
        })
      },
    },
  }
</script>
<i18n>
{
	"en": {
		"subtitle": "The care for the environment has always been extremely valuable for SGD Group,As a matter of fact the company provides/offers products for the interventions and the relative operational instructions. Its products has a low environmental impact, as it quickly deteriorates, transforming itself into water and oxygen",
		"t0": {
			"text": "The product emanates gaseous oxygen",
			"title": "Eco-friendly product"
		},
		"t1": {
			"text": "No risks for the aquatic environment",
			"title": "Care for the acquatic environment"
		},
		"t2": {
			"text": "The entire sanitisation process is carried out with full respect for the environment ",
			"title": "Low environmental impact"
		},
		"title": "Care for the environment"
	},
	"it": {
		"subtitle": "L'attenzione per l’ambiente occupa una posizione di rilievo per il progetto SanyBeach.  Il prodotto ha infatti un basso impatto ambientale in quanto degrada rapidamente, trasformandosi in acqua e ossigeno libero.",
		"t0": {
			"text": "Il prodotto sprigiona ossigeno libero gassoso",
			"title": "Prodotto sicuro e sostenibile"
		},
		"t1": {
			"text": "L’intervento non comporta <b>nessun rischio per l’ecosistema marino</b> in quanto il prodotto utilizzato degrada velocemente",
			"title": "Attenzione all'ambiente acquatico"
		},
		"t2": {
			"text": "L’intero processo di sanificazione è effettuato nel pieno rispetto ambientale",
			"title": "BASSO IMPATTO AMBIENTALE"
		},
		"title": "L'attenzione per l'ambiente",
    "s0": {
      "title": "Nessun vincolo di prodotto",
      "text": "Il prodotto per la sanificazione consigliato è un prodotto sostenibile, che risponde a pieno al protocollo elaborato. Non è comunque obbligatorio il suo utilizzo, <b>lo stabilimento è libero di utilizzare prodotti alternativi</b> purchè rispettino i parametri dati"
    }
	}
}
</i18n>
